.webcam_container {
  background-color: #000;
  height: calc(100vh);
}

.webcam {
  top: calc(50%);
  width: 100%;
  height: 100%;
}

.shutterButton {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  bottom: 40px;
}

.photoSelectButton {
  color: #fff;
  z-index: 10000;
  margin-inline: 20px;
  /* width: 100px;
  height: 20px; */
  bottom: 40px;
}
