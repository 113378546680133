/* #00A968 */
.smContainer {
  margin: 0 auto;
  width: 320px;
}

h1 {
  font-size: 22px;
}

label {
  color: #333;
  font-weight: bold;
}

.bg_general {
  background-color: #00a968;
}

.text_general {
  color: #00a968;
}

.bg_sub {
  background-color: #a8a800;
}

.text_sub {
  color: #a8a800;
}

.general_button {
  background-color: #00a968;
  border-color: #00a968;
}

.general_button:hover,
.general_button:active,
.general_button:checked {
  background-color: #008f58;
  border-color: #008f58;
}

.sub_button {
  background-color: #a8a800;
  border-color: #a8a800;
}

.sub_button:hover,
.sub_button:active,
.sub_button:checked {
  background-color: #7d7d00;
  border-color: #7d7d00;
}

.carousel-indicators {
  margin-top: 20px;
}

.carousel-indicators [data-bs-target] {
  background-color: #666;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-inline: 12px;
}
