.search_box {
  background-color: #d8d8d8;
  width: 100%;
  height: 60px;
}

.search_box_InputGroup {
  padding: 10px 20px;
  height: 40px;
}
.search_box_Button {
  background-color: #fff;
  border: none;
  border-radius: 20px;
  height: 40px;
}

.search_box_TextBox {
  background-color: #fff;
  border: none;
  border-radius: 20px;
  height: 40px;
}
