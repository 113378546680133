.home-carousel {
  width: 100%;
  position: fixed;
  left: 50%;
  bottom: 328px;
  transform: translateX(-50%);
}

.home-footer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.home-footer-top {
  bottom: 106px;

  background-color: #77ffcb;
  width: 100%;
  height: 220px;
  border-radius: 36px;
}
.home-footer-top_caption {
  margin-top: 6px;
  margin-bottom: 0;
  color: #666;
  font-size: 18px;
}
.home-footer-top_points {
  font-size: 48px;
  line-height: 48px;
}
.home-footer-top_yen {
  margin-left: 8px;
  font-size: 24px;
  line-height: 48px;
}
.home-footer-top_exchange {
  position: absolute;
  left: 50%;
  bottom: 52px;
  transform: translateX(-50%);
  height: 80px;
}
.home-footer-top_gp-l {
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 160px;
}
.home-footer-top_gp-r {
  z-index: -1;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 160px;
}

.home-footer-bottom {
  bottom: 0;

  background-color: #32ffb0;
  width: 100%;
  height: 100px;
}
.home-footer-bottom_home {
  position: absolute;
  left: 4px;
  height: 60px;
}
.home-footer-bottom_notice {
  position: absolute;
  left: 68px;
  height: 60px;
}

.home-footer-button {
  bottom: 50px;

  width: 100px;
  height: 100px;
  border-radius: 50%;
}
